.values_area_outer > div {
  margin-right: 30px;
}
.values_area_wrapper_1 {
  display: flex;
  justify-content: flex-end;
  font-size: 1.1rem;
}
.values_area_wrapper_2 {
  display: flex;
}
.values_area_wrapper_2 .values_area {
  margin-left: 30px;
  margin-bottom: 10px;
}
.values_area_wrapper_2 .values_area:nth-child(2) {
  margin-left: auto;
}
.values_area {
  display: flex;
  align-items: center;
}
.values_area .legend_wrapper {
  margin: 0;
}
.legend_wrapper .legend {
  font-size: 13px;
  margin: 0;
  margin-right: 5px;
  color: rgb(136, 132, 216);
}
.legend_wrapper .legend2 {
  font-size: 13px;
  margin: 0;
  margin-right: 5px;
  color: rgb(130, 202, 157);
}
.values_area div {
  margin-right: 10px;
}

@media screen and (max-width: 1023px) {
  .values_area_outer > div {
    margin-right: 0;
  }
  .values_area {
    font-size: 15px;
  }
  .values_area_wrapper_2 .values_area {
    margin-left: 0;
  }
  .values_area div {
    margin-right: 4px;
  }
  .is_two {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .values_area_wrapper_1 {
    justify-content: center;
  }
  .values_area {
    font-size: 14px;
  }
  .values_area_wrapper_2 {
    display: block;
  }
  .first {
    color: rgb(136, 132, 216);
  }
  .second {
    color: rgb(103, 174, 131);
  }
  .values_area_wrapper_2 .values_area {
    margin-bottom: 0;
  }
  .is_two {
    font-size: 12px;
  }
  .none {
    display: none;
  }
}
.modal_message_area {
  text-align: center;
}
.modal_message_area i {
  font-size: 2em;
  margin-bottom: 10px;
  color: #ff8181;
}
.modal_close_btn {
  border: 1px solid lightgrey;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgb(230, 230, 230);
  color: #373737;
}
.modal_close_btn:hover {
  background-color: #ccc;
}
.modal_close_btn:focus {
  outline: none;
}

@media screen and (max-width: 767px) {
  .modal_message_area i {
    font-size: 25px;
  }
  .modal_message_area p {
    font-size: 14px;
  }
}

.chart_wrapper {
  height: 450px;
  margin-top: 120px;
}
.yAxis_unit_wrapper {
  position: relative;
  height: 15px;
  background-color: #ffffff;
}
.yAxis_unit {
  position: absolute;
  left: 50px;
  top: 8px;
  z-index: 1;
}
.tooltip {
  margin: 0;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}
.tooltip p {
  margin-bottom: 0;
}
.tooltip .name_a {
  color: #8884d8;
}
.tooltip .name_b {
  color: #82ca9d;
}
.hamburger {
  display: flex;
  align-items: center;
  color: #211c70;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  background-color: #ebeaf4;
  height: 100%;
  left: 0;
  top: 0;
  max-width: 300px;
  width: 100%;
  z-index: 100;
  padding: 15px 30px 10px;
}
.hamburger i {
  font-size: 25px;
}
.hamburger > i:first-of-type {
  margin-right: 6px;
}
.hamburger > div {
  margin-right: 5px;
}
.hamburger > i:last-of-type {
  transition: all 0.3s linear;
}
.open i:last-of-type {
  transform: rotate(-180deg);
}
.menu {
  position: absolute;
  left: 0;
  top: 63px;
  width: 100%;
  max-width: 360px;
  background-color: #ebeaf4;
  transform: translateY(-300px);
  transition: all 0.4s ease-in-out;
}
.menu_open {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.menu a {
  display: block;
  color: #211c70;
  font-weight: bold;
  text-decoration: none;
  margin: 0;
  padding: 8px 20px;
  padding-left: 50px;
  transition: 0.3s;
  user-select: none;
}
.menu li:last-child a {
  padding-bottom: 15px;
}
.menu a:hover {
  background-color: #f5f9ca;
}
.active {
  color: #ffa500 !important;
}
.close_btn_area {
  padding: 10px;
  padding-top: 16px;
  padding-right: 20px;
  display: flex;
  justify-content: end;
}

@media screen and (max-width: 1700px) {
  .menu {
    max-width: 280px;
  }
}

@media screen and (max-width: 1500px) {
  .menu {
    max-width: 220px;
  }
}

@media screen and (max-width: 1024px) {
  .menu {
    left: -260px;
    top: 300px;
    height: 100vh;
    max-width: 260px;
    z-index: 500;
  }
  .menu_open {
    left: 0;
    transform: translateY(-300px);
  }
  .menu a {
    padding-left: 60px;
  }
}

@media screen and (max-width: 767px) {
  .hamburger {
    width: auto;
  }
  .menu {
    max-width: 220px;
  }
  .menu a {
    padding-left: 40px;
  }
}

.header {
  background: #ebeaf4;
  padding: 15px 30px 10px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 63px;
  z-index: 100;
}
.site_name {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  left: 200px;
  z-index: 200;
}
.site_name i {
  font-size: 25px;
  margin-right: 12px;
  color: #5982c1;
}
.site_name div {
  color: #211c70;
}
.header .active {
  color: #ffa500;
}
.alert_note_link {
  position: relative;
}
.alert {
  margin-left: auto;
  margin-right: 30px;
  position: relative;
}
.alert .badge {
  position: absolute;
  top: -5px;
  left: 12px;
  font-size: 12px;
  font-weight: bold;
  line-height: 13px;
  border-radius: 50%;
  width: 13px;
  height: 13px;
  background-color: tomato;
  border: 4px solid tomato;
  color: #ffffff;
  text-align: center;
  box-sizing: unset;
}
.alert a {
  margin-right: 0;
  display: flex;
}
.alert a:hover {
  text-decoration: none;
}
.alert i {
  font-size: 25px;
  color: #211c70;
  display: block;
  margin-right: 5px;
}
.alert div {
  color: #211c70;
  font-weight: bold;
}
.alert .error {
  color: #f25d43;
}

@media screen and (max-width: 1023px) {
  .header {
    padding: 10px 30px 10px;
  }
  .header .link_logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-right: 0;
  }
  .header .logo {
    height: 38px;
    margin-right: 0;
  }
  .notfound_header {
    height: 57px;
  }
}

@media screen and (max-width: 767px) {
  .alert {
    margin-right: 10px;
  }
  .header .logout {
    margin-right: 0;
  }
  .logout_str {
    display: none;
  }
}

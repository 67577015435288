.values_area_outer > div {
  margin-right: 20px;
}
.values_area_wrapper_1 {
  display: flex;
  justify-content: flex-end;
  font-size: 1.1rem;
}
.values_area_wrapper_2 {
  display: flex;
}
.values_area_wrapper_2 .values_area {
  margin-left: 20px;
  margin-bottom: 8px;
}
.values_area_wrapper_2 .values_area:nth-child(2) {
  margin-left: auto;
}
.values_area {
  display: flex;
  align-items: center;
}
.values_area .legend_wrapper {
  margin: 0;
}
.legend_wrapper .legend {
  font-size: 13px;
  margin: 0;
  margin-right: 5px;
  color: rgb(136, 132, 216);
}
.legend_wrapper .legend2 {
  font-size: 13px;
  margin: 0;
  margin-right: 5px;
  color: rgb(130, 202, 157);
}
.is_one {
  margin-bottom: 8px;
}
.values_area div {
  margin-right: 8px;
}
.values_area div:last-child {
  margin-right: 0;
}
.values_area .max {
  color: #ff6699;
}
.values_area .min {
  color: #8884d8;
}
.values_area .average {
  color: rgb(111, 110, 128);
}
.is_two {
  font-size: 15.5px;
}

@media screen and (max-width: 1023px) {
  .values_area_outer > div {
    margin-right: 0;
  }
  .values_area_wrapper_1 {
    font-size: 15px;
  }
  .values_area_wrapper_2 .values_area {
    margin-left: 0;
  }
  .values_area div {
    margin-right: 4px;
  }
  .is_two {
    font-size: 13px;
  }
}

@media screen and (max-width: 767px) {
  .values_area_wrapper_1 {
    justify-content: center;
    font-size: 14px;
  }
  .values_area_wrapper_2 {
    display: block;
  }
  .first {
    color: rgb(136, 132, 216);
  }
  .second {
    color: rgb(103, 174, 131);
  }
  .values_area_wrapper_2 .values_area {
    margin-bottom: 0;
    white-space: nowrap;
  }
  .is_two {
    font-size: 12px;
  }
  .none {
    display: none;
  }
}
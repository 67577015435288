.container {
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;
  position: relative;
  min-height: calc(100vh - 89px);
  margin: 0 auto;
}
.inner {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateY(-40%) translateX(-50%);
  width: 90%;
  max-width: 751px;
  text-align: center;
}
.inner h2 {
  margin-bottom: 30px;
}
.link_wrapper {
  text-align: right;
  margin-top: 30px;
  position: relative;
}
.link_wrapper a {
  font-weight: bold;
  color: #211c70;
  transition: 0.3s;
  position: absolute;
  width: 160px;
  right: 0;
  bottom: -100px;
}
.link_wrapper a:hover {
  text-decoration: none;
  color: #ffa500;
}

@media screen and (max-width: 767px) {
  .inner h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }
  .inner p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .link_wrapper a {
    bottom: -30px;
  }
}

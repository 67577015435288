h1 {
  font-weight: normal;
}
h1 span {
  font-size: 1.4rem;
}
.outer_container {
  position: relative;
  margin: 2em;
  padding-bottom: 3em;
}
.container {
  max-width: 1024px;
  margin: 20px auto;
}
.output_date {
  position: absolute;
  top: 0;
  right: 0;
}
.measurement_screen_page_graph {
  position: relative;
  padding: 0px 0 56px;
}
.button_container {
  width: 100%;
  position: fixed;
  bottom: 0;
  text-align: center;
  padding: 1em 0;
  background-color: #F7F5F5;
  margin-left: -2em;
  z-index: 1;
}
@media print {
  .button_container {
    width:0;
    height:0;
    display: none;
    visibility: hidden;
  }
  }
.display_name {
  font-size: 1.2rem;
  margin-left: 20px;
}
.search_btn {
margin-left: 30px;
border: none;
border-radius: .25em;
color: #FFF;
padding: 5px 10px;
cursor: pointer;
background-color: #5982c1;
font-weight: bold;
transition: 0.2s ease-in-out;
height: 38.38px;
width: 84px;
}
.search_btn:hover {
background-color: #3d6cb5;
}
.search_btn:focus {
outline: none;
}
.search_btn:disabled {
background-color: #5982c1;
opacity: 0.8;
cursor: auto;
}
.btn {
  margin-left: 30px;
  border: none;
  border-radius: .25em;
  color: #FFF;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #8d8d8d;
  font-weight: bold;
  transition: 0.2s ease-in-out;
  height: 38.38px;
  width: 84px;
}
.btn:hover {
  background-color: #797979;
}
.btn:focus {
  outline: none;
}
.btn:disabled {
  background-color: #8d8d8d;
  opacity: 0.8;
  cursor: auto;
}
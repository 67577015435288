.footer {
  background: #ebeaf4;
  padding: 15px 30px 10px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  height: 100px;
}
.sytem_name {
  font-weight: bold;
  color: #211c70;
}
.footer a,
.footer div {
  width: 33.3%;
}
.footer div {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .footer {
    flex-direction: column;
    height: auto;
  }
  .footer a {
    margin-top: 10px;
  }
  .footer a,
  .sytem_name {
    margin-bottom: 10px;
  }
  .footer a,
  .footer div {
    width: auto;
  }
  .footer .space {
    padding-bottom: 20px;
  }
}

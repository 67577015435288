.alert_style {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 20px;
  background-color: white;
  z-index: 500;
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  text-align: center;
  box-sizing: border-box;
}
.alert_close_btn {
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgb(230, 230, 230);
  color: #373737;
}
.alert_close_btn:hover {
  background-color: #ccc;
}
.ModalOpen {
  animation: openModal 0.4s ease-out forwards;
}
.ModalClosed {
  animation: closeModal 1s ease-out forwards;
}
@keyframes openModal {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
@keyframes closeModal {
  0% {
    opacity: 1;
  }
  50% {
   opacity: 0.8;
  }
  100% {
   opacity: 0;
  }
}

@media screen and (max-width: 767px) {
  .alert_style i {
    font-size: 25px !important;
  }
  .alert_style p {
    font-size: 14px;
  }
}
.chart_wrapper {
  height: 450px;
  margin-top: 120px;
}
.yAxis_unit_wrapper {
  position: relative;
  height: 15px;
  background-color: #ffffff;
}
.yAxis_unit {
  position: absolute;
  left: 50px;
  top: 8px;
  z-index: 1;
}
.yAxis_unit_wrapper .yAxis_unit:nth-child(2) {
  left: 900px;
}
.tooltip {
  margin: 0;
  padding: 10px;
  background-color: #ffffff;
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
}
.value_1 {
  color: #8884d8;
}
.value_2 {
  color: #82ca9d;
}
.modal {
  position: fixed;
  z-index: 700;
  background-color: white;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  padding: 20px;
  width: 90%;
  max-width: 700px;
  margin: 0 auto;
  border: 1px solid #eee;
  box-shadow: 0 2px 2px #ccc;
  text-align: center;
}
